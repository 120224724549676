import axios from "axios";
import { Message } from "element-ui";
import querystring from "querystring";
import { getToken, removeToken } from "@/Util/auth";
import { logout } from "@/Api/login";
import errorCode from "./errorCode";
import router from "@/Router";
import { filterObject } from "@/Tools";
var URL ='https://cpq.shipgroup.net'

// if(window.location.hostname == 'cpq.shipgroup.net'){
//     URL = 'http://cpq.shipgroup.net:8888'
// } else {
//     URL = 'http://192.168.12.12:8080'
// }
// URL ='http://192.168.19.26:8080'//本地服务器地址
//  URL ='http://192.168.20.229:8080'//本 地服务器地址
  // URL='http://192.168.4.32:8080' //本地
const service = axios.create({
  timeout: 30000,
    baseURL:URL,
  withCredentials: true,
  validateStatus: function(status) {
    return status >= 200 && status <= 500; // 默认的
  }
});

// request拦截器
service.interceptors.request.use(
  config => {
    // 判断是否需要token

    const isToken = config.noToken;
    let token = getToken();
    //let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDczMzk4NDgsImlhdCI6MTY0NzMxMTA0OCwidXNlcm5hbWUiOiJhZG1pbiJ9.hEjonzWz8BBZa9ZkvB-tIawdGsvJVRz9psONes-hc60';
    if (token && !isToken) {
      config.headers["x-auth-token"] = `${token}`; // token
    }

    // post请求
    if (
      config.method === "post" &&
      config.headers["Content-Type"] =="application/json"
    ) {
     /* let d = filterObject(config.data);
          config.data = querystring.stringify(d);*/
        config.data = config.data;
    } else if (config.method === "get") {
            config.params = filterObject(config.params);
        }else if (
        config.method === "post" &&
        config.headers["Content-Type"] =="multipart/form-data"
    ) {
        /* let d = filterObject(config.data);
             config.data = querystring.stringify(d);*/
        config.data = config.data;
    } else {
            /*config.data = filterObject(config.data);*/
            config.data = config.data;
        }
        return config;
    },
        error => {
            return Promise.reject(new Error(error));
        }
    );

service.interceptors.response.use(
    res => {

        const status = Number(res.status) || 200;
        const message =
            res.data.msg || errorCode[status] || errorCode["default"];
        let data = null;
        if (res.status === 203) {
            removeToken();
            router.push({
        name: "login"
      });
      return;
    } else if (
      status !== 200 ||
      res.data.code == 20000 ||
      res.data.code == 20011 ||
      res.data.code == 30015 ||
      res.data.code == 30008 ||
      res.data.code == 20002
    ) {
      Message({
        message: message,
        type: "error"
      });
      return Promise.reject(new Error(message));
    } else {
      data = res.data;
    }
    return data;
  },
  error => {
    return Promise.reject(new Error(error));
  }
);

export default service;
