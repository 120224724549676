import request from "@/Request";


export const llylogin = prarms => {
  return request({
    url: `/sjzx/login`,
    headers: {
      'Content-Type': 'application/json'
    },
    method: "post",
    noToken: true,
    data: prarms
  });
};


