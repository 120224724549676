import request from "@/Request";
import qs from "qs" ;


export function mainList(){
    return request({
        url: `/sjzx/sys/productMenu/list`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
    });
};
export const standardList= prarms =>{
    return request({
        url: `/sjzx/alo/std/list`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const projectList= prarms =>{
    return request({
        url: `/sjzx/biz/project/list`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const projectAdd= prarms =>{
    return request({
        url: `/sjzx/biz/microdemand/stdAdd`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};

//冷热通道标准接口
export const projectAddhotandcold= prarms =>{
    return request({
        url: `/sjzx/biz/coldHotChannel/stdAdd`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};

export const projectAddNew= prarms =>{
    return request({
        url: `/sjzx/biz/project/add`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const projectEdit= prarms =>{
    return request({
        url: `/sjzx/biz/project/edit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const projectDel= prarms =>{
    return request({
        url: `/sjzx/biz/project/del`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const downFile= prarms =>{
    return request({
        url: `/sjzx/alo/download`,
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob',
        method: "post",
        data: prarms
    });
};
export const getConfigList= prarms =>{
    return request({
        url: `/sjzx/alo/std/detail`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getConfigFin= prarms =>{
    return request({
        url: `/sjzx/alo/std/finish`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getConfigFinList= prarms =>{
    return request({
        url: `/sjzx/alo/list`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getConfigFinDel= prarms =>{
    return request({
        url: `/sjzx/alo/remove`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getConfigFinDetail= prarms =>{
    return request({
        url: `/sjzx/alo/detail`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getConfigPLFin= prarms =>{
    return request({
        url: `/sjzx/alo/trans`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const productMenuList= prarms =>{
    return request({
        url: `/sjzx/biz/index/list`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const microdemandAdd= prarms =>{
    return request({
        url: `/sjzx/biz/microdemand/add`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
//冷热通道需求下一步
export const microdemandAddhotandcold= prarms =>{
    return request({
        url: `/sjzx/biz/coldHotChannel/add`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};

export const tempEdit= prarms =>{
    return request({
        url: `/sjzx/alo/tempEdit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const tempDel= prarms =>{
    return request({
        url: `/sjzx/alo/tempDel`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const tempList= prarms =>{
    return request({
        url: `/sjzx/alo/tempList`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const customAdd= prarms =>{
    return request({
        url: `/sjzx/alo/custom/add`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const aloChange= prarms =>{
    return request({
        url: `/sjzx/alo/tempChange`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const aloDelTrue= prarms =>{
    return request({
        url: `/sjzx/alo/del`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const aloEditTrue= prarms =>{
    return request({
        url: `/sjzx/alo/edit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const aloChangeTrue= prarms =>{
    return request({
        url: `/sjzx/alo/change`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const addFinalTrue= prarms =>{
    return request({
        url: `/sjzx/alo/custom/addFinal`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const passChange= prarms =>{
    return request({
        url: `/sjzx/sys/user/edit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const goCommit= prarms =>{
    return request({
        url: `/sjzx/alo/commit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const microdemandChange= prarms =>{
    return request({
        url: `/sjzx/biz//microdemand/change`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const customSearch= prarms =>{
    return request({
        url: `/sjzx/alo/custom/search`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const customAddBatch= prarms =>{
    return request({
        url: `/sjzx/alo/custom/addBatch`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const shopAddBatch= prarms =>{
    return request({
        url: `/sjzx/shop/addBatch`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const customAddBatchFinal= prarms =>{
    return request({
        url: `/sjzx/alo/custom/addBatchFinal`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const getProDetial= prarms =>{
    return request({
        url: `sjzx/biz/single/${prarms}`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
    });
};
export const addBuyCar= prarms =>{
    return request({
        url: `/sjzx/shop/edit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const addMiniDate= prarms =>{
    return request({
        url: `/sjzx/biz/oneDemand/stdAdd`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const addSingleList= prarms =>{
    return request({
        url: `/sjzx/shop/transSingle`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const nextConfig= prarms =>{
    return request({
        url: `/sjzx/biz/oneDemand/add`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const changeCustom= prarms =>{
    return request({
        url: `/sjzx/biz/oneDemand/change`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const shopGetCount= prarms =>{
    return request({
        url: `/sjzx/shop/getCount`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
    });
};
export const shopGetDetail= prarms =>{
    return request({
        url: `/sjzx/shop/detail`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
    });
};
export const shopDel= prarms =>{
    return request({
        url: `/sjzx/shop/del`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const shopCustom= prarms =>{
    return request({
        url: `/sjzx/shop/custom`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const shopTrans= prarms =>{
    return request({
        url: `/sjzx/shop/trans`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};
export const aloEditSets= prarms =>{
    return request({
        url: `/sjzx/alo/aloEdit`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};

export const uploadImg= prarms =>{
    return request({
        url: `/sjzx/biz/upload/img`,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        method: "post",
        data: prarms
    });
};
export const goAllCommit= prarms =>{
    return request({
        url: `/sjzx/alo/commitBatch`,
        headers: {
            'Content-Type': 'application/json'
        },
        method: "post",
        data: prarms
    });
};

